// src/components/VideoPlayer.js
import React, { useEffect, useRef } from 'react';
import Hls from 'hls.js';

const VideoPlayer = ({ streamUrl }) => {
  const videoRef = useRef(null);

  useEffect(() => {
    const video = videoRef.current;

    // Check if HLS.js is supported
    if (Hls.isSupported()) {
      const hls = new Hls();
      hls.loadSource(streamUrl);  // Load the stream URL
      hls.attachMedia(video);

      hls.on(Hls.Events.MANIFEST_PARSED, function () {
        video.play();
      });

      return () => {
        hls.destroy();  // Clean up the HLS.js instance on component unmount
      };
    } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
      // Fallback for Safari (which has native HLS support)
      video.src = streamUrl;
      video.addEventListener('canplay', () => video.play());
    }
  }, [streamUrl]);

  return (
    <div>
      <video
        ref={videoRef}
        controls
        width="100%"
        height="auto"
        style={{ maxWidth: '400px' }}
      >
        <p>Your browser does not support HTML5 video.</p>
      </video>
    </div>
  );
};

export default VideoPlayer;
