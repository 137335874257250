import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Dropzone from 'react-dropzone';

const characterLimits = {
    microblog: 280,
    blog: Infinity, // No limit for blogs
    post: 125,  // Instagram-like caption for posts
    clip: 125   // Instagram-like caption for stories/reels
};

const PostEditor = () => {
    const [auth, setAuth] = useState(false);
    const [password, setPassword] = useState('');
    const [content, setContent] = useState('');
    const [title, setTitle] = useState('');
    const [files, setFiles] = useState([]);
    const [type, setType] = useState('microblog');

    const handleLogin = async () => {
        const res = await fetch(process.env.REACT_APP_API_URL + 'login', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ password })
        });

        const data = await res.json();
        if (data.success) {
            setAuth(true);
        } else {
            alert('Wrong password');
        }
    };

    const handleUpload = (acceptedFiles) => {
        if (type === 'clip' && acceptedFiles.length > 1) {
            alert('A clip can only have one media file.');
            return;
        }
        setFiles(acceptedFiles.slice(0, 20)); // Limit posts to 20 files
    };

    const handleSubmit = async () => {
        const formData = new FormData();
        files.forEach(file => {
            formData.append('files', file);
        }); // Append all files
        formData.append('type', type);
        formData.append('content', content);

        if (title.length > 0) {
            formData.append('title', title);
        }

        const res = await fetch(process.env.REACT_APP_API_URL + 'upload', {
            method: 'POST',
            body: formData,
            credentials: 'include' // Send session cookie
        });

        const data = await res.json();
        alert('Uploaded:', data.url);
    };

    if (!auth) {
        return (
            <div style={{margin: '20px', display: 'flex', flexDirection: 'column', maxWidth: '300px'}}>
                <h2>Admin Login</h2>
                <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Enter Password"
                />
                <button onClick={handleLogin}>Login</button>
            </div>
        );
    }
    
    const maxChars = characterLimits[type];
    const remainingChars = maxChars === Infinity ? '∞' : maxChars - content.length;
    
    return (
        <div style={{margin: '20px'}}>
            <h2>New Post</h2>
            <select value={type} onChange={(e) => setType(e.target.value)}>
                <option value="microblog">Microblog</option>
                <option value="blog">Blog</option>
                <option value="post">Post (Images/Videos)</option>
                <option value="clip">Clip (Story/Reel)</option>
            </select>
        
            {type === 'blog' && (
                <div>
                    <label>Title</label>
                    <input type="text" value={title} onChange={(e) => setTitle(e.target.value)} placeholder="Title" />
                </div>
            )}

            <label>Content</label>
            <ReactQuill value={content} onChange={setContent} />
            <p style={{ color: remainingChars < 0 ? 'red' : 'white' }}>
                {remainingChars} characters left
            </p>
            <label>Media</label>
            <Dropzone onDrop={handleUpload} accept="image/*,video/*">
                {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps()} style={{ border: '1px dashed #ccc', padding: '10px', cursor: 'pointer' }}>
                        <input {...getInputProps()} />
                        {files.length > 0 
                            ? <p>Selected: {files.map(f => f.name).join(', ')}</p> 
                            : 'Drag & drop or click to upload'}
                    </div>
                )}
            </Dropzone>
    
            <button disabled={remainingChars < 0} onClick={handleSubmit}>Publish</button>
        </div>
    );
};

export default PostEditor;
