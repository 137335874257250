
import React, {useState, useEffect} from 'react';
import avatar from './avatar.jpg';

function unescapeHtml(escaped) {
    return escaped
        .replace(/&amp;/g, "&")
        .replace(/&lt;/g, "<")
        .replace(/&gt;/g, ">")
        .replace(/&quot;/g, '"')
        .replace(/&#039;/g, "'");
}

function escapeHtml(unsafe){
    return unsafe
         .replace(/&/g, "&amp;")
         .replace(/</g, "&lt;")
         .replace(/>/g, "&gt;")
         .replace(/"/g, "&quot;")
         .replace(/'/g, "&#039;");
 }

// Utility to format timestamp
function convertTimestamp(timestamp) {
    const date = new Date(timestamp);
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    return date.toLocaleDateString('en-US', options);
}

const isVideo = (url) => {
    return url.endsWith('.mp4') || url.endsWith('.mov') || url.endsWith('.avi');
};

function Post(post) {
    const renderMedia = () => {
        return (
            <div className="media">
            {post.file_urls && post.file_urls.length > 0 && (
                <div>
                    {post.file_urls.map((url, index) => (
                        <div key={index} className="media-item" style={{ marginBottom: '10px' }}>
                            {isVideo(url) ? (
                                <video className="post-video" controls>
                                    <source src={`${process.env.PUBLIC_URL}${url}`} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                            ) : (
                                <img
                                    className="post-image"
                                    src={`${process.env.PUBLIC_URL}${url}`}
                                    alt={`Post media ${index}`}
                                    style={{ marginRight: '5px' }}
                                />
                            )}
                        </div>
                    ))}
                </div>
            )}
        </div>
        );
    };
    return (
        <div
            className="questrial-regular"
            style={{
                display: 'flex',
                flexDirection: 'column',
                borderRadius: '5px',
                padding: '20px',
                marginBottom: '10px',
                boxSizing: 'border-box',
                maxWidth: ['microblog', 'post', 'clip'].includes(post.type) ? '450px' : '800px',
            }}
        >
            <div style={{
                display: 'flex', 
            }}>
                <img style={{ width: 40, height: 40, borderRadius: '50%' }} src={avatar} alt="User Avatar" />
                <div style={{ marginLeft: 10 }}>
                    <p style={{ margin: 0 }}>
                        <strong>@dubbeldutch</strong> • {convertTimestamp(post.timestamp)}
                    </p>
                </div>
            </div>
            {post.type === 'blog' && <h3>{post.title}</h3>}
            {post.type === 'blog' && renderMedia()}
            <p dangerouslySetInnerHTML={{ __html: post.content }} />
            {post.type !== 'blog' && renderMedia()}
        </div>
    )
};


export default function PostPage({params}) {
    const [post, setPost] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    useEffect(() => {
        const fetchPost = async () => {
            if (loading || post) {
                return;
            };
        
            setLoading(true); // Start loading
        
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}post/${params.slug}`);
                const data = await response.json();
                setPost(data);
            } catch (error) {
                setError("Failed to load posts");
            } finally {
                setLoading(false); // Stop loading
            }
        };
      
        fetchPost();
    }, [loading, params, post]);

    return (
        <div>
            {loading && <p>Loading...</p>}
            {error && <p>{error}</p>}
            {post && <Post {...post} />}
        </div>
    );
}