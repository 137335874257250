import React, { useEffect, useState } from 'react';
import avatar from './avatar.jpg';

function unescapeHtml(escaped) {
    return escaped
        .replace(/&amp;/g, "&")
        .replace(/&lt;/g, "<")
        .replace(/&gt;/g, ">")
        .replace(/&quot;/g, '"')
        .replace(/&#039;/g, "'");
}

function escapeHtml(unsafe){
    return unsafe
         .replace(/&/g, "&amp;")
         .replace(/</g, "&lt;")
         .replace(/>/g, "&gt;")
         .replace(/"/g, "&quot;")
         .replace(/'/g, "&#039;");
 }

// Utility to format timestamp
function convertTimestamp(timestamp) {
    const date = new Date(timestamp);
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    return date.toLocaleDateString('en-US', options);
}

const PostsPage = () => {
    const [filters, setFilters] = useState({
        microblog: true,
        blog: true,
        post: true,
        clip: true,
    });
    const [expandedPosts, setExpandedPosts] = useState({});
    const [posts, setPosts] = useState([]);  // Store all posts
    const [filteredPosts, setFilteredPosts] = useState(posts);
    const [loading, setLoading] = useState(false);  // Track loading state
    const [error, setError] = useState(null);  // Track any errors
    const [offset, setOffset] = useState(0);  // To track the offset for fetching posts    
    const limit = 10;
    const [totalPosts, setTotalPosts] = useState(10); // assume at least 10
    useEffect(() => {
        const fetchPosts = async () => {
            if (posts.length === offset + limit || posts.length === totalPosts) {
                return;             
            }

            if (loading) {
                return;
            }; // Prevent multiple requests at once
        
            setLoading(true); // Start loading
        
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}posts?offset=${offset}`);
                const data = await response.json();
                setTotalPosts(data.totalPosts);
                setPosts((prevPosts) => [...prevPosts, ...data.posts]); // Append new posts
            } catch (error) {
                setError("Failed to load posts");
            } finally {
                setLoading(false); // Stop loading
            }
        };
      
        fetchPosts();
      }, [offset, loading, totalPosts, posts]);

      useEffect(() => {
        const handleScroll = () => {
          if (loading) return;  // Don't load more if already loading
      
          const scrollPosition = window.innerHeight + document.documentElement.scrollTop;
          const nearBottom = document.documentElement.offsetHeight - scrollPosition < 200;  // Trigger if 200px from the bottom
      
          if (nearBottom) {
            setOffset((prevOffset) => prevOffset + 10);  // Increase offset to load next set of posts
          }
        };
      
        window.addEventListener('scroll', handleScroll);
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, [loading]);

    useEffect(() => {
        const activeFilters = Object.keys(filters).filter((filter) => filters[filter]);
        setFilteredPosts(posts.filter((post) => activeFilters.includes(post.type)));
    }, [filters, posts]);

    const toggleExpand = (postId) => {
        setExpandedPosts((prevExpandedPosts) => ({
            ...prevExpandedPosts,
            [postId]: !prevExpandedPosts[postId],
        }));
    };
      
    const renderPost = (post) => {
        const renderMedia = (post) => {
            return (
                <div className="media">
                    {post.file_urls && post.file_urls.length > 0 && (
                        <div>
                            {post.file_urls.map((url, index) => (
                                <div key={index} className="media-item" style={{ marginBottom: '10px' }}>
                                    {isVideo(url) ? (
                                        <video className="post-video" controls>
                                            <source src={`${process.env.PUBLIC_URL}${url}`} type="video/mp4" />
                                            Your browser does not support the video tag.
                                        </video>
                                    ) : (
                                        <img
                                            className="post-image"
                                            src={`${process.env.PUBLIC_URL}${url}`}
                                            alt={`Post media ${index}`}
                                            style={{ marginRight: '5px' }}
                                        />
                                    )}
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            )
        };
        const postBorders = {
            microblog: '2px solid #FF7043', // Muted Red
            blog: '2px solid #FFB74D', // Muted Orange
            post: '2px solid #FFEB3B', // Muted Yellow
            clip: '2px solid #8BC34A', // Muted Green
        };

        const isVideo = (url) => {
            return url.endsWith('.mp4') || url.endsWith('.mov') || url.endsWith('.avi');
        };

        const isExpanded = expandedPosts[post.id];
        const content = post.type === 'blog' ? unescapeHtml(post.content) : post.content;
        const truncatedContent = content.length > 300 ? content.substring(0, 300) + '...' : content;

        return (
            <div
                className="questrial-regular"
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    border: postBorders[post.type] || '2px solid #ccc',
                    borderRadius: '5px',
                    padding: '10px',
                    marginBottom: '10px',
                    boxSizing: 'border-box',
                    maxWidth: ['microblog', 'post', 'clip'].includes(post.type) ? '450px' : '800px',
                }}
            >
                <a className="post-link" href={`/update/${post.slug}`}>
                    <div style={{
                        display: 'flex', 
                    }}>
                        <img style={{ width: 40, height: 40, borderRadius: '50%' }} src={avatar} alt="User Avatar" />
                        <div style={{ marginLeft: 10 }}>
                            <p style={{ margin: 0 }}>
                                <strong>@dubbeldutch</strong> • {convertTimestamp(post.timestamp)}
                            </p>
                        </div>
                    </div>
                    {post.type === 'blog' && <h3>{post.title}</h3>}
                </a>
                {post.type === 'blog' && (
                    renderMedia(post)
                )}
                <p dangerouslySetInnerHTML={{ __html: isExpanded ? content : truncatedContent }} />
                {content.length > 300 && (
                    <button className="black-btn" onClick={() => toggleExpand(post.id)}>
                        {isExpanded ? 'Show less' : 'Show more'}
                    </button>
                )}
                {post.type !== 'blog' && (
                    renderMedia(post)
                )}
            </div>
        );
    };

    // const handleFilterChange = (event) => {
    //     const { name, checked } = event.target;
    //     setFilters((prevFilters) => ({
    //         ...prevFilters,
    //         [name]: checked,
    //     }));
    // };

    // const checkboxColors = {
    //     microblog: '#FF7043',
    //     blog: '#FFB74D',
    //     post: '#FFEB3B',
    //     clip: '#8BC34A',
    // };

    return (            
        <div style={{ padding: '10px', paddingTop: 0 }}>
            {/* TODO FIX THESE FILTERS */}
            {/* <div style={{ marginBottom: '20px' }}>
                <div>
                    {['microblog', 'blog', 'post', 'clip'].map((type) => (
                        <label key={type} style={{ marginRight: '15px' }}>
                            <input
                                type="checkbox"
                                name={type}
                                checked={filters[type]}
                                onChange={handleFilterChange}
                                style={{ accentColor: checkboxColors[type] }}
                            />
                            <span style={{ userSelect: 'none' }}>
                                {(() => {
                                    switch (type) {
                                        case 'microblog':
                                            return 'thoughts';
                                        case 'blog':
                                            return 'tl;drs';
                                        case 'post':
                                            return 'posts';
                                        case 'clip':
                                            return 'clips';
                                        default:
                                            return type.charAt(0).toUpperCase() + type.slice(1);
                                    }
                                })()}
                            </span>
                        </label>
                    ))}
                </div>
            </div> */}
            {loading && <p>Loading more posts...</p>}

            {filteredPosts.length === 0 ? (
            <p>No updates available.</p>
            ) : (
                filteredPosts.map((post) => (
                <div key={post.id} style={{ padding: '10px' }}>
                {renderPost(post)}
                </div>
            ))
            )}

            {error && <p>{error}</p>}
        </div>
    );
};

export default PostsPage;