import { useState } from 'react';
import VideoPlayer from './VideoPlayer.tsx';

const LiveStream = () => {
  const streamUrl = 'https://dubbeldutch.com/hls/live.m3u8';
  const [didJoin, setDidJoin] = useState(false);
  return (
    <div style={{margin: '20px'}}>
      <h1>Rare Earth Tones Livestream</h1>
      {!didJoin && <button onClick={() => setDidJoin(true)}>Join</button>}
      {didJoin && <VideoPlayer streamUrl={streamUrl} />}      
    </div>
  );
};

export default LiveStream;