import {useState} from 'react';
import { createPortal } from 'react-dom';
import SDCard from "./SDCard.tsx";
import SDCardEject from "./SDCardEject.tsx";
import CollectedWorks from './CollectedWorks.tsx';
import { Link, Route, Switch } from "wouter";
import Contact from './Contact.tsx';
import YoutubePlayer from './YoutubePlayer.tsx';
import Home from './Home.tsx';
import DownloadManager from './DownloadManager.tsx';
import CosmicTonePoems from './CosmicTonePoems.tsx';
import LiveStream from './LiveStream.tsx';
import PostEditor from './PostEditor.tsx';
import PostsPage from './PostsPage.tsx';
import PostPage from './PostPage.tsx';

function App() {
  const [isPressingSD, setIsPressingSD] = useState(false);
  const [isSDCardInserted, setIsSDCardInserted] = useState(false);
  const [playlistId, setPlaylistId] = useState(null);
  return (
      <div className="app">
        <div className="sticky-header">
          <div className="header-container">
              <div 
                className="sd icon-transition"               
                >
                  <div
                    className={isSDCardInserted ? 'hidden' : ''}
                    onMouseDown={() => setIsPressingSD(true)}
                    onMouseUp={() => setIsPressingSD(false)}
                    onMouseOut={() => setIsPressingSD(false)}
                    onClick={() => setIsSDCardInserted(true)}
                  >
                    <SDCard 
                      isPressingSD={isPressingSD} 
                    />
                  </div>
                  <div
                    className={isSDCardInserted ? '' : 'hidden'}
                    onClick={() => setIsSDCardInserted(false)}
                    onMouseDown={() => setIsPressingSD(true)}
                    onMouseOut={() => setIsPressingSD(false)}
                    onMouseUp={() => setIsPressingSD(false)}
                  >
                    <SDCardEject isPressingSD={isPressingSD}  />
                  </div>
              </div>
            <h1 className="heading">dubbel dutch</h1>
            <Link className="nav-link" href="/">home</Link>
            <Link className="nav-link" href="/works">works</Link>
            <Link className="nav-link" href="/updates">updates</Link>
            <Link className="nav-link" href="/contact">contact</Link>
          </div>
          <iframe title="Follow on BandCamp" scrolling="no" style={{paddingLeft: '15px', paddingBottom: '10px', border: 0, height: '33px', width: '190px'}} src="https://bandcamp.com/band_follow_button_classic/1229291481"/>
\        </div>
        {isSDCardInserted && createPortal(
          <DownloadManager onClose={() => setIsSDCardInserted(false)} />,
          document.body
        )}
        <div>
          <Switch>
            <Route path="/" component={Home} />
            <Route path="/livestream" component={() => <LiveStream />} />
            <Route path="/works" component={() => <CollectedWorks setPlaylistId={setPlaylistId} />} />
            <Route path="/contact" component={Contact} />
            <Route path="/cosmictonepoems" component={CosmicTonePoems} />
            <Route path="/admin" component={PostEditor} />
            <Route path="/updates" component={PostsPage} />
            <Route path="/update/:slug" component={PostPage} />
          </Switch>
          </div>
          <YoutubePlayer playlistId={playlistId} setPlaylistId={setPlaylistId} />
          <div className="footer">{`© ${(new Date()).getFullYear()} rare earth studios • 🌈`}</div>
      </div>
  );
}

export default App;
